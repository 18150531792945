import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, Component } from 'react';
import {appendScript, renderTime} from './appendScript'
import Instructions from './Instructions';
import {timerFunc} from './timerFunc';
import ReactCountdownClock from 'react-countdown-clock'
import { CountdownCircleTimer, useCountdown } from 'react-countdown-circle-timer'

//https://codesandbox.io/s/tender-bogdan-qd35m?file=/src/index.js
//https://github.com/vydimitrov/react-countdown-circle-timer/tree/master/packages/mobile#react-native-countdown-circle-timer
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {      // https://www.pluralsight.com/blog/software-development/suspense-react-18-explained
        data: 'constructor called',
        loading: true,
        secondsLeft: 0,
        os: '',
        ver: ''
    }

      //this.setOS = this.setOS.bind(this);
  }

    // setOS() {
    //     this.setState({os: this.state.os})
    // }

  componentWillMount() {
      console.log('componentWillMount called');
  }

  getData() {
      let value = timerFunc().then(data => {
          this.state.loading = false
          this.state.secondsLeft = data['secondsLeft']
          this.state.os = data['o']
          this.state.ver = data['v']
          this.forceUpdate()    // force rendering the class again
      })
  }

  componentDidMount() {
      this.getData();
  }

  myCallback() {
  }

  render() {

      if (this.state.loading) {return (<h1>Loading data...</h1>);}

      return (
          <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)', width: '600px', height: '600px'
          }}>
            <h1 id="content-holder">Time until release of {this.state.os} {this.state.ver}</h1>
            <div className="timer-wrapper" style={{position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'}}>
                <CountdownCircleTimer
                  key={0}
                  isPlaying
                  duration={this.state.secondsLeft}/*{timerFunc()}*/
                  colors="#7E2E84"
                  onComplete={() => [true, 1000]}
                  size = '400'
                  children = {({ remainingTime }) => {
                      const hours = Math.floor(remainingTime / 3600)
                      const minutes = Math.floor((remainingTime % 3600) / 60)
                      const seconds = remainingTime % 60

                      return hours + ':' + minutes + ':' + seconds
                  }}
                >
                </CountdownCircleTimer>
            </div>
          </div>
      )
  }
}

export default App;
