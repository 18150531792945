import React, { Component } from 'react';
import {timerFunc} from './timerFunc';

export default class Instructions extends Component {

    render() {
        return(
            <div>{timerFunc()}</div>
        )
    }
}
