
/*
Return the time difference between specific dates in seconds
 */

// https://versions-releases-nextjs-be-deploy.pages.dev/api/githubapi  Or // http://localhost:3000/api/githubapi
// export async function timerFuncTest() {
//     let response = await fetch('https://versions-releases-nextjs-be-deploy.pages.dev/api/githubapi', {
//              //mode: 'no-cors',
//              /*headers: {
//                  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//                  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
//              }*/
//          });
//     let responseJson = await response.json();
//     return responseJson.versions
// }


export async function timerFunc() {

    let activeVersion = await getCurrentVersionFromApiAsync()   // { o: 'ios', v: '8.2.2', d: '30-04-2023', s: 1 }
    if (activeVersion === undefined)
        return

    new Date().toLocaleDateString('en-us', {weekday: "long", year: "numeric", month: "short", day: "numeric"})

    let endDate = activeVersion['d']    //30-04-2023
    let dateArray = endDate.split("-");

    endDate = new Date(parseInt(dateArray[2]), parseInt(dateArray[1]), parseInt(dateArray[0]), 17) // fixed hour 17:00

    const currentDate = new Date()
    var seconds = Math.ceil((endDate.getTime() - currentDate.getTime()) / (1000)) // (hours) 3600000 = 60 * 60 * 1000
    console.log("seconds = " + seconds)

    activeVersion['secondsLeft'] = seconds
    return activeVersion
}


async function getCurrentVersionFromApiAsync() {

    let versions = await getVersionsFromApiAsync()
    if (versions === undefined)
        return
    let currentVersion = getCurrentVersion(versions)
    return currentVersion
}


async function getVersionsFromApiAsync() {

    let response = await fetchRemoteAPI();
    let responseJson = await extractAllVersions(response);
    if (responseJson === undefined)
        return

    // "{"data":{"versions":[{"o":"iOS","v":"8.2.2","d":"30-04-2023","s":1},{"o":"Android","v":"8.2.4","d":"30-04-2023","s":0},{"o":"iOS","v":"8.2.3","d":"30-06-2023","s":0}]}}"
    let tmp = responseJson.data.versions
    return tmp
}


async function fetchRemoteAPI () {
    return new Promise(resolve => {
        let serverResponse = fetch('https://versions-releases-nextjs-be-deploy.pages.dev/api/githubapi');       // http://localhost:3000/api/githubapi
        resolve(serverResponse);
    });
}


async function extractAllVersions (response) {
    return new Promise(resolve => {
        let responseJson = response.json()
        resolve(responseJson);
    });
}


function getCurrentVersion(versions) {
    for (let i = 0; i < versions.length; i++) {
        let version = versions[i]
        if (version['s'] === 1) {
            console.log("found current version")
            return version;
        }
    }

    return null;    // no active version was found
}
